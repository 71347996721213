<template>
	<h1>Dialogs</h1>

	<div class="text-block bump-b-30">
		<pre
			v-highlightjs
		><code class="language-javascript">import { useDialogStore } from '@/stores/DialogStore'

export default {
	methods() {

		showDialogGeneric() {
			// A generic dialog with some text.
			dialog('Some message', { title: 'Hello, World' })
		}

		showDialogConfirm() {
			// A confirmation dialog.
			confirm(onSubmit, 'Are you sure...', { persistent: true })
		}

		showDialogCriticalConfirm() {
			// A critical confirmation dialog
			// This will require you to type 'I understand' for confirmation
			confirmCritical(onConfirm, {
				title: 'Lorem Ipsum', // Default "Permanent Deletion"
				actionKey: 'I understand', // Default "delete"
				params: { ... },
			})
		}

		showDialogLogin() {
			// Display a bespoke dialog.
			// For more elaborate dialogs that require their own component.
			dialogByName('DialogAuth', { page: 'login' })
		}	
	},
}</code></pre>
	</div>

	<div class="text-block bump-b-30">
		<h3>Options</h3>
		<pre
			v-highlightjs
		><code class="language-javascript options">// Options apply to all non-bespoke dialogs.
// I.e. dialog() / confirm() / confirmCritical()
{	
	// Basics
	title: String,
	message: String, // Explicit parameter for confirm and confirmCritical.
	html: Boolean, // Render message as HTML or plain text (default plain)
	okText: String, // Confirm button text
	cancelText: String, // Cancel button text
	hideCancel: Boolean,
	
	// Callbacks
	onConfirm: Function, // When you click OK
	onCancel: Function, // When you click cancel
	validate: Function, // This will block doConfirm() when false.
	
	// Advanced
	warning: String or Boolean // Red 'Warning!' or custom text before title
	persistent: Boolean // Blocks softClose: clicking outside / ESC key.
	width: Number, // Override default width of 2.5rem/250px (pixel value)
}</code></pre>
	</div>

	<div class="text-block bump-b-30">
		<h3>How It Works</h3>
		<ul class="pad">
			<li>
				The dialog component <span class="code">TheDialog.vue</span> is conditionally embedded at the
				root in <span class="code">App.vue</span>. It contains the shadow box and will load the
				appropriate dialog template.
			</li>
			<li>
				Regular dialogs all share the same template:
				<span class="code">/dialogs/main/DialogMain.vue</span>. Bepoke dialogs use their own
				templates: <span class="code">/dialogs/DialogFooBar.vue</span>.
			</li>
			<li>
				<span class="code">TheDialog.vue</span> communicates with the
				<span class="code">DialogStore</span> where all the logic is housed.
			</li>
		</ul>
	</div>

	<div class="text-block bump-b-30">
		<h3>Implementation</h3>
		<ul class="pad">
			<li>
				Make sure to register any bespoke dialogs (dialogByName) in the
				<span class="code">TheDialog.vue</span> component.
			</li>
			<li>
				Note that <span class="code">confirm()</span> <span class="code">confirmCritical()</span> is
				just a wrapper for <span class="code">dialog()</span> that sets som defaults and creates a
				shortcut for certain parameters like 'onConfirm'.
			</li>
		</ul>
	</div>

	<div class="text-block bump-b-30">
		<h3>Forms</h3>
		<p>
			Many dialogs have forms inside. For optimal behavior with the <span class="code">ENTER</span> key,
			there's a few things that need to be kept in mind depending on the scenarios.
		</p>
		<ul class="pad">
			<li>
				<p>
					<b>Single stage forms:</b> In most cases the form will only have a single step and the
					dialog should be closed as soon as the form has been successfully submitted. To achieve
					this:
				</p>
				<ul>
					<li>
						Do <b>not</b> use an actual <span class="code">&#60;form&#62;</span> element because
						this will cause additional <span class="code">ENTER</span> key behavior we don't want.
					</li>
					<li>
						Add the form's validation stage to the store.
					</li>
					<li class="pad">Add the doConfirm to the submit</li>
				</ul>
				<p>
					This way everything will work as normal but the dialog won't close unless the form has
					been validated. The benefit of this approach is that the
					<span class="code">ENTER</span> button will work even when no input is in focus.
				</p>
				<p>
					An example of this is the label creation dialog:
					<span class="code">/dialogs/DialogLabelCreate.vue</span>
				</p>
				<pre v-highlightjs><code class="language-html">{{ html1.join('\n') }}</code></pre>
				<pre v-highlightjs><code class="language-javascript">mounted() {
	this.dialogStore.setValidation(this.validate)
},
methods: {
	// The validation stage should return true/false.
	validate() {
		return 1 + 1 == 2
	}
}
</code></pre>
			</li>
			<li>
				<b>Multi-stage forms:</b>You can either add a validation function that keeps track of the
				stage of your form (the "proper" way of doing it) but this adds quiet some complexity, so in
				cases like this it's easier to just use the same technique as embedded forms:
			</li>
			<li>
				<b>Embedded forms:</b> These are used when a certain form also lives elsewhere on the site,
				but can also be used in a dialog. In cases like this (and multi-stage forms):
				<ul>
					<li>Disable the dialog's <span class="code">ENTER</span> key functionality.</li>
					<li>
						Use the <span class="code">&#60;form&#62;</span> element and rely on it's native's
						<span class="code">ENTER</span> key behavior. This means that the
						<span class="code">ENTER</span> key will only work when an input it in focus.
					</li>
					<li class="pad">
						In the script controlling the form, whenever you are ready to close the form, you
						either emit an exit event (when embedded) or call dialogStore.doCancel directly (when
						not embedded).
					</li>
				</ul>

				Examples:
				<ul>
					<li>
						The authentication dialog (login/signup):
						<span class="code">/dialogs/DialogAuth.vue</span>
					</li>
					<li>
						The dialog to create rooms and lists:
						<span class="code">/dialogs/DialogLabelCreate.vue</span>
					</li>
				</ul>

				<pre v-highlightjs><code class="language-html">{{ html2.join('\n') }}</code></pre>
				<pre v-highlightjs><code class="language-html">{{ html3.join('\n') }}</code></pre>
				<pre v-highlightjs><code class="language-javascript">mounted() {
	// Disable dialog's ENTER key behavior
	this.dialogStore.disableEnterKey()
},
methods: {
	validateFinalStage() {
		if (everythingOk) {
			dialogStore.doCancel() // When the form is inside the dialog.
			this.$emit('exit') // When teh form is embedded
		}
	}
}
</code></pre>
			</li>
		</ul>
	</div>

	<div class="text-block bump-b-30">
		<h3>Demo</h3>
		<FormButton
			value="Simple Text"
			@click="
				dialogStore.dialog('I never liked donuts.', {
					title: 'Hello, world',
					okText: 'Goodbye',
					hideCancel: true,
				})
			"
		/>
		<FormButton
			value="Confirm"
			@click="
				dialogStore.confirm(onConfirm, 'Are you sure you want to end the world?', {
					onCancel,
					cancelText: 'No I\'m not',
					okText: 'Yes I am',
				})
			"
		/>
		<FormButton
			value="Critical Confirm"
			@click="
				dialogStore.confirmCritical(onConfirm, {
					title: 'There\'s No Way Back',
					actionKey: 'I understand',
					params: { cancelText: 'Abort' },
				})
			"
		/>
		<FormButton
			value="Warning"
			@click="
				dialogStore.confirm(null, 'You are about to destroy the planet.', {
					warning: true,
					title: 'Apocalypse',
				})
			"
		/>
		<FormButton
			value="Persistent"
			@click="
				dialogStore.dialog('Try clicking outside.', {
					persistent: true,
					title: 'I\'m a Persistent Dialog',
				})
			"
		/>
		<FormButton value="Nested" @click="showDialogNested" />
	</div>

	<div class="text-block bump-b-30">
		<h3>Dialogs by Name</h3>
		<!-- prettier-ignore -->
		<ul>
			<li><a href="#" @click.prevent="dialogStore.dialogByName('DialogArtistCreate', { submittedName: 'Marcel Duchamp' })">DialogArtistCreate</a></li>
			<li><a href="#" @click.prevent="dialogStore.dialogByName('DialogAuth')">DialogAuth - login</a></li>
			<li><a href="#" @click.prevent="dialogStore.dialogByName('DialogAuth', { page: 'signup' })">DialogAuth - signup</a></li>
			<li><a href="#" @click.prevent="dialogStore.dialogByName('DialogAuth', { page: 'wait-list' })">DialogAuth - wait list</a></li>
			<li><a href="#" @click.prevent="dialogStore.dialogByName('DialogB2Images', { itmId: 'x5ebijwIc' })">DialogB2Images</a></li>
			<li><a href="#" @click.prevent="dialogStore.dialogByName('DialogClusterCreate', { type: 'list' })">DialogClusterCreate - list</a></li>
			<li><a href="#" @click.prevent="dialogStore.dialogByName('DialogClusterCreate', { type: 'room' })">DialogClusterCreate - room</a></li>
			<li><a href="#" @click.prevent="dialogStore.dialogByName('DialogLabelCreate', {
				submittedValue: 'foo-bar',
				labelCategory: 'label',
				defaultType: 'subject'
			})">DialogLabelCreate - label</a></li>
			<li><a href="#" @click.prevent="dialogStore.dialogByName('DialogLabelCreate', {
				submittedValue: 'foo-bar',
				labelCategory: 'material',
				defaultType: 'substrate'
			})">DialogLabelCreate - material</a></li>
			<li><a href="#" @click.prevent="dialogStore.dialogByName('DialogLabelTypes', {
				labelCategory: 'label',
			})">DialogLabelTypes - labels</a></li>
			<li><a href="#" @click.prevent="dialogStore.dialogByName('DialogLabelTypes', {
				labelCategory: 'material',
			})">DialogLabelTypes - materials</a></li>
			<li><a href="#" @click.prevent="dialogStore.dialogByName('DialogShare', {
				url: 'https://arthur.io/themoenen',
				text: 'Hello world',
				imgUrl: 'https://arthur.io/imgs/arthur-cover.jpg',
				id: 'vDPCiwvyrOBB',
				entityType: 'user',
			})">DialogShare</a></li>
			<li><a href="#" @click.prevent="dialogStore.dialogByName('DialogSkipLine')">DialogSkipLine</a></li>
		</ul>
	</div>
</template>

<script>
// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormButton from '@/components/FormButton'

// Internal
import { injectMetaData } from '@/use/MetaData'
import { delay } from '@/use/Helpers'

export default {
	name: 'DocModulesDialogs',
	components: { FormButton },
	setup() {
		const dialogStore = useDialogStore()
		injectMetaData({
			title: 'Doc: Dialogs',
		})
		return { dialogStore }
	},
	data() {
		return {
			html1: [
				'<!-- Do not use <form> element. -->',
				'<div>',
				'	<!-- Form content here -->',
				'	<formButton value="submit" @click="dialogStore.doConfirm" />',
				'</div>',
			],
			html2: [
				'<!-- First stage. -->',
				'<form @submit.prevent="validateFirstStage">',
				'	<!-- Form content here -->',
				'	<formButton value="submit" />',
				'</div>',
				'',
				'<!-- Final stage. -->',
				'<form @submit.prevent="validateFinalStage">',
				'	<!-- Form content here -->',
				'	<formButton value="submit" />',
				'</div>',
			],
			html3: ['<!-- When the form is embdedded -->', '<TheForm @exit="dialogStore.doCancel" />'],
		}
	},
	methods: {
		async onConfirm() {
			await delay(1000)
			alert('Action confirmed')
		},
		async onCancel() {
			await delay(1000)
			alert('Action cancelled')
		},
		showDialogNested() {
			this.dialogStore.dialog(
				'This dialog opens another dialog. Nested dialogs skip the intro animation.',
				{
					title: 'Nested Dialogs',
					okText: 'Open nested',
					onConfirm: this.showNestedDialog2,
				}
			)
		},
		showNestedDialog2() {
			this.dialogStore.dialog('This dialog is nested', {
				okText: 'Back',
				onConfirm: this.showDialogNested,
			})
		},
	},
}
</script>

<style scoped lang="scss">
.text-block button {
	margin: 0 0.1rem 0.1rem 0;
}
</style>
